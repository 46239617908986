
import DataTable from '@/components/DataTable/index.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import FinancialPlan from '@/models/FinancialPlan'
import ViewModel from '@/models/ViewModel'
import WebMessage from '@/models/WebMessage'
import { currencyMask } from '@/models/interface/Masks'
import { groupBy } from 'lodash'
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import FinancialPlanProductModule from '@/models/FinancialPlanProductModule'
import SalesRepConsolidateTable from './SalesRepConsolidateTable.vue'

@Component({
  components: {
    IconAction,
    DataTable,
    FormInput,
    UserPicker,
    SalesRepConsolidateTable,
  },
})
export default class SalesRepConsolidateModal extends ViewModel {
  public item: FinancialPlan = new FinancialPlan()

  public goalModule = new FinancialPlanProductModule()

  public modal: boolean = false

  public can_collapse: boolean = false

  public loading: boolean = false

  public goal_refs: any = []

  public locked_items: any = []

  public locked_values: any = []

  public bulk_months: any = []

  public collapsed_groups: any = {
    ssl: false,
    ccl: false,
  }

  public sales_rep_consolidate_fields: any = [
    {
      key: 'month',
      label: 'Month',
      sortable: false,
      show: true,
      thStyle: { width: '20rem' },
      tdClass: 'align-middle text-center',
      thClass: 'align-middle text-center',
    },
    {
      key: 'ssl',
      label: 'SSL',
      sortable: false,
      show: true,
      thStyle: { width: '20rem' },
      tdClass: 'align-middle text-center',
      thClass: 'align-middle text-center',
    },
    {
      key: 'ccl',
      label: 'CCL',
      sortable: false,

      show: true,
      thStyle: { width: '20rem' },
      tdClass: 'align-middle text-center',
      thClass: 'align-middle text-center',
    },
    {
      key: 'total',
      label: 'Total',
      sortable: false,
      show: true,
      thStyle: { width: '20rem' },
      tdClass: 'align-middle text-center',
      thClass: 'align-middle text-center',
    },
  ]

  public top_header_fields: any = [
    {
      key: 'sales_rep',
      label: '',
      sortable: false,
      filter: false,
      show: true,
      style: 'width: 300px',
      colspan: 1,
    },
    {
      key: 'ssl',
      label: 'SSl',
      sortable: false,
      filter: false,
      show: true,
      colspan: 5,
      class: 'align-middle text-center border',
    },
    {
      key: 'ccl',
      label: 'CCl',
      sortable: false,
      filter: false,
      show: true,
      colspan: 5,
      class: 'align-middle text-center border',
    },
    {
      key: 'total',
      label: '',
      sortable: false,
      filter: false,
      show: true,
    },
  ]

  public sub_header_fields: any = [
    {
      key: 'sales_rep',
      label: 'Sales Rep',
      show: true,
      class: 'align-middle text-center border',
    },
    {
      key: 'ssl_q1',
      label: 'Q1',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ssl_q2',
      label: 'Q2',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ssl_q3',
      label: 'Q3',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ssl_q4',
      label: 'Q4',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ssl_total',
      label: 'Total',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },

    {
      key: 'ccl_q1',
      label: 'Q1',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ccl_q2',
      label: 'Q2',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ccl_q3',
      label: 'Q3',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ccl_q4',
      label: 'Q4',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'ccl_total',
      label: 'Total',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
    },
    {
      key: 'total',
      label: 'Total',
      show: true,
      style: 'width: 200px',
      class: 'align-middle text-center border',
      type: 'currency_abbreviate',
    },
  ]

  public temp_goal: any = []

  public sales_rep: any = { id: '' }

  public saving: boolean = false

  public is_selecting_sales_rep: boolean = false

  public create_new: boolean = false

  public viewing: any = {
    main_table: false,
    select_sales_rep: false,
    month_table: false,
  }

  public get loading_state() {
    return this.loading || this.saving || !this.ready
  }

  public get no_sales_rep_selected() {
    return !this.sales_rep.id || this.sales_rep.id.length === 0
  }

  public get masks() {
    return {
      currency: currencyMask,
    }
  }

  public get header_fields() {
    return this.top_header_fields.filter((item: any) => item.show)
  }

  public get sub_head_fields() {
    return this.sub_header_fields.filter((item: any) => item.show)
  }

  public ready: any = false

  public get local_modal() {
    return this.modal
  }

  public set local_modal(val) {
    this.modal = val
  }

  public toggle_columns(key: string) {
    this.collapsed_groups[key] = !this.collapsed_groups[key]
    this.item.sub_loading = true
    let sub_header_fields = this.sub_header_fields.map((item: any) => {
      if (item.key.includes(`${key}_`) && !item.key.includes('total')) {
        item.show = !this.collapsed_groups[key]
      }
      return item
    })
    Vue.set(this, 'sub_header_fields', sub_header_fields)

    setTimeout(() => {
      this.item.sub_loading = false
    }, 500)
  }

  public async consolidatedSalesRep(item: FinancialPlan) {
    this.loading = true

    return item
      .salesRepConsolidatedGoals()
      .then(() => {
        this.loading = false
        this.saving = false
        this.ready = true
        this.viewing.main_table = true
      })
      .catch(() => {
        this.loading = false
      })
  }

  public toogleLocks(region: string, product: string, month_index: number, value: number) {
    region = region.toLowerCase()
    product = product.toLowerCase()

    let key = `${region}-${product}-${month_index}`

    if (this.locked_items.includes(key)) {
      this.locked_items = this.locked_items.filter((item: any) => item !== key)
      delete this.locked_values[key]
    } else {
      this.locked_items.push(key)
      this.locked_values[key] = value
    }
  }

  public col_sum(product: string, col_name: string = '', row_index: any = null) {
    if (col_name.includes('total')) {
      let prod_total = this.item.sub_table_data.reduce(
        (acc: any, item: any) => acc + item[product][col_name],
        0,
      )
      if (row_index !== null) {
        // this.item.sub_table_data[row_index].total = prod_total
      }
      return prod_total
    }

    let quarter = col_name.replace(`${product}_`, '')

    let filtered = this.item.sub_table_data.filter(data => data[product][quarter])

    let sum = filtered.reduce((acc: any, item: any) => acc + item[product][quarter].goal, 0)

    return sum
  }

  public sum_row_totals(row_index: number) {
    const { ccl, ssl } = this.item.sub_table_data[row_index]
    this.item.sub_table_data[row_index].total = ccl.ccl_total + ssl.ssl_total

    return this.item.sub_table_data[row_index].total
  }

  public calculate_totals() {
    return this.item.sub_table_data.reduce((acc: any, item: any) => acc + item.total, 0)
  }

  public searchMonth(arr: any, month: string, product: string, return_undef = false) {
    let found = arr.find(a => {
      let splited = a.period_start_at.split('T')
      return moment(splited[0]).format('MMMM') === month && a.product === product
    })
    if (!found && !return_undef) return { goal: null }
    return found
  }

  public setMonthTable(pre_load: boolean = true) {
    let months = moment.months().map((monthName: string) => {
      let obj: any = {
        month: monthName,
        ccl: 0,
        ssl: 0,
        total: 0,
      }

      if (!pre_load) {
        obj.ccl = this.searchMonth(this.goal_refs, monthName, 'ccl').goal || 0
        obj.ssl = this.searchMonth(this.goal_refs, monthName, 'ssl').goal || 0
      }

      return obj
    })

    this.temp_goal = months
  }

  public editSalesRep(sales_rep: any) {
    this.loading = true

    this.viewing.main_table = false

    // this.viewing.select_sales_rep = false

    this.sales_rep = { id: sales_rep.id || sales_rep.value, name: sales_rep.name }

    // @ts-ignore
    this.item
      .salesRepConsolidatedInspection(sales_rep.id)
      .then(response => {
        // this.viewing.select_sales_rep = true

        this.goal_refs = response

        if (!response.length) {
          this.create_new = true
        } else {
          // this.create_new = false
        }

        this.setMonthTable(false)

        this.loading = false

        this.viewing.main_table = false

        this.viewing.month_table = true
      })
      .catch(() => {
        this.loading = false
      })
  }

  public cancelEdition() {
    if (this.create_new) {
      this.create_new = false
      this.consolidatedSalesRep(this.item)
      this.sales_rep = { id: '' }
    } else {
      this.temp_goal = null
      this.modal = false
      this.saving = false
      this.sales_rep = { id: '' }
    }
  }

  public save() {
    this.saving = true

    let updated: any = []

    this.temp_goal.forEach((goal: any) => {
      let ssl_goal = this.searchMonth(this.goal_refs, goal.month, 'ssl', true)

      let ccl_goal = this.searchMonth(this.goal_refs, goal.month, 'ccl', true)

      let base_payload = {
        goal: 0,
        period_start_at: moment().month(goal.month).startOf('month').format('MM/DD/YYYY'),
        period_end_at: moment().month(goal.month).endOf('month').format('MM/DD/YYYY'),
      }

      if (this.create_new) {
        updated.push({
          ...base_payload,
          create: true,
          goal: goal.ssl,
          product: 'ssl',
        })
        updated.push({
          ...base_payload,
          create: true,
          goal: goal.ccl,
          product: 'ccl',
        })
      } else {
        if (ssl_goal === undefined) {
          updated.push({
            ...base_payload,
            create: true,
            product: 'ssl',
          })
        } else if (goal.ssl !== ssl_goal.goal) {
          updated.push({
            id: ssl_goal.id,
            goal: goal.ssl || 0,
          })
        }
        if (ccl_goal === undefined) {
          updated.push({
            ...base_payload,
            create: true,
            product: 'ccl',
          })
        } else if (goal.ccl !== ccl_goal.goal) {
          updated.push({
            id: ccl_goal.id,
            goal: goal.ccl || 0,
          })
        }
      }
    })
    if (!updated.length) {
      WebMessage.info('No changes to save')
      this.goBack()
      this.saving = false

      return
    }

    this.item
      .saveSalesRepConsolidation(this.sales_rep.id, updated)
      ?.then(response => {
        this.consolidatedSalesRep(this.item)
        this.goBack()
        this.saving = false
      })
      .catch(() => {
        this.item.is_requesting = false
        this.item._loading = false
        this.saving = false
      })
  }

  public newConsolidation() {
    this.setMonthTable()
    this.create_new = true
    this.sales_rep = { id: '' }
    this.viewing.main_table = false
    this.viewing.month_table = true
    this.viewing.select_sales_rep = true
  }

  public selectedRep(e) {
    if (!e.value || this.loading) return

    this.viewing.main_table = false

    if (!this.create_new) {
      this.viewing.month_table = false
    }

    this.loading = true

    this.setMonthTable()

    this.sales_rep = e

    setTimeout(() => {
      this.editSalesRep(e)
    }, 500)
  }

  public goBack() {
    this.viewing.main_table = true
    this.viewing.month_table = false
    this.viewing.select_sales_rep = false
    this.create_new = false
  }

  public splited_values(key) {
    return key.split('_')
  }
}
