
import IconAction from '@/components/IconAction/IconAction.vue'
import ViewModel from '@/models/ViewModel'
import Component from 'vue-class-component'
import FormInput from '@/components/FormInput/FormInput.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import { currencyMask, percentagDecimaleMask } from '@/models/interface/Masks'
import Widget from '@/components/Widget/Widget.vue'
import DataTable from '@/components/DataTable/index.vue'
import FinancialPlan from '@/models/FinancialPlan'
import WebMessage from '@/models/WebMessage'
import { Ref, Vue, Watch } from 'vue-property-decorator'
import Api from '@/models/Api'
import numeral from 'numeral'
import moment from 'moment'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import ModelFileUploader from '@/components/ModelFile/ModelFileUploader.vue'
import { groupBy, keyBy, min } from 'lodash'
import BigNumber from 'bignumber.js'
import FinancialPlanProductModule from '@/models/FinancialPlanProductModule'
import Fields from './financial-plan-home-fields'
import SalesRepConsolidateModal from './components/SalesRepConsolidateModal.vue'

@Component({
  components: {
    IconAction,
    FormInput,
    DatePicker,
    Widget,
    DataTable,
    SelectPicker,
    ModelFileUploader,
    SalesRepConsolidateModal,
  },
})
export default class GoalPlanningHome extends ViewModel {
  @Ref() public sales_rep_modal!: SalesRepConsolidateModal

  @Ref() public file_input!: any

  @Ref() readonly overviewTable!: HTMLFormElement

  @Ref() public validator!: any

  @Ref() public dataTable!: any

  public action: string = 'create'

  public selected_option: any = 'region'

  public source_year = 0

  public goals: FinancialPlan[] = []

  public model: FinancialPlan = new FinancialPlan()

  public import_to_plan: FinancialPlan | any = null

  public increment: number = 0

  public clone_from: string | null = null

  public loading: boolean = false

  public ready: boolean = false

  public import_modal: boolean = false

  public importing_file: boolean = false

  public fieldFilters: any = {}

  public query: string[] = []

  public records: number = 0

  public loaded_items: FinancialPlan[] = []

  public snapshot_name: string = ''

  public snapshot_description: string = ''

  public bulk_months: any = []

  public managing_model: boolean = false

  public pagination_options: any = [
    { name: '10', value: '10' },
    { name: '25', value: '25' },
    { name: '50', value: '50' },
    { name: '100', value: '100' },
  ]

  public file_limit: number = 1

  public locked_items: any = []

  public locked_values: any = {}

  public locked_rows: any = []

  public locked_columns: any = []

  private is_calculating: boolean = false

  private can_update_values: boolean = true

  public collapsed_groups: any = {
    ssl: false,
    ccl: false,
  }

  public show_sales_rep_modal: boolean = false

  public sumRegionsInRowByIndex(rowIndex: number, product: string, region: string | null = null) {
    return this.bulk_months[rowIndex][product].reduce((acc: number, item: any) => {
      if (region) {
        if (item.key === region) {
          acc += item.value
        }
      } else if (item.key !== 'total') {
        acc += item.value
      }

      return acc
    }, 0)
  }

  public sumRegionColumn(product: string, region: string) {
    return this.bulk_months.reduce((total: number, item: any) => {
      let found = item[product].find(p => p.key === region)
      if (found) {
        total += found.value
      }
      return total
    }, 0)
  }

  /**
   * Used on footer row to calculate each column
   *
   * maybe refactor this later, some conditions are not needed anymore, due to code update added calculate_table_totals()
   *
   * @param product
   * @param prod_key
   * @param rowIndex
   */
  public column_totals(product: any, prod_key: any, rowIndex: any) {
    /**
     * this below is for the last column in table "Total"
     */
    if (
      product === prod_key
      || product === 'month'
      || (product === 'total' && prod_key.includes('total_'))
    ) {
      let all_totals = 0

      if (rowIndex === undefined) {
        this.bulk_months.forEach((item: any, iIndex) => {
          this.model.AVAILABLE_PRODUCTS.forEach(product => {
            all_totals += this.sumRegionsInRowByIndex(iIndex, product)
          })
        })
        return all_totals
      }
      // this is for thelast column "Total"
      // sum all totals from the regions
      this.model.AVAILABLE_PRODUCTS.forEach(product => {
        all_totals += this.sumRegionsInRowByIndex(rowIndex, product)
      })

      return all_totals
    }

    /**
     * this is the sum column total in products
     */
    if (prod_key.includes(`total_${product}`) && rowIndex === undefined) {
      let all_totals = 0

      this.bulk_months.forEach((element: any, el_indx) => {
        all_totals += this.sumRegionsInRowByIndex(el_indx, product)
      })

      return all_totals
    }

    /**
     * This is the sum of regions from a given @param product [SSL,CCL] in a fiven month = @param rowIndex
     * ex:
     * rowIndex = 0; // January row
     * product = ssl;   // ssl || ccl
     *
     * Sum all product region value in row  @param rowIndex
     * column total from ccl or ssl
     */
    if (prod_key === 'total') {
      return this.sumRegionsInRowByIndex(rowIndex, product)
    }

    /* format prod_key
     * ex if key === 'midwest_ssl'
     * expected_region = 'midwest'
     */
    let expected_region = prod_key
    if (prod_key.includes(`_${product}`)) {
      expected_region = prod_key.split('_')[0]
    }

    if (prod_key.includes(`total_${product}`)) {
      // total_ssl, total_ccl
      expected_region = prod_key.split('_')[0]
    }

    /**
     * This is the total of a region column of the products
     * this is for the table footer sum of column
     */

    return this.sumRegionColumn(product, expected_region)
  }

  public calculate_table_totals() {
    if (this.is_calculating) return // prevent double calls to this method
    // when addPlan is clicked the can_update_values is set to false, so when the modal opens the inputs dont emit @input without user interaction
    if (this.can_update_values) {
      this.is_calculating = true
      this.bulk_months.forEach((element: any, index: number) => {
        this.bulk_months[index].total = 0
        this.model.AVAILABLE_PRODUCTS.forEach(product => {
          // calculate region total
          let total_index = element[product].findIndex(f => f.key === 'total')
          let total_res = this.sumRegionsInRowByIndex(index, product)
          if (total_index >= 0) {
            total_res = Number(total_res)
            this.bulk_months[index][product][total_index].value = total_res
            this.bulk_months[index].total += total_res
          }
        })
      })

      setTimeout(() => {
        this.is_calculating = false
      }, 300)
    }
  }

  public get fields() {
    return Fields
  }

  public get masks() {
    return {
      currency: currencyMask,
      percentage: percentagDecimaleMask,
    }
  }

  public get modal_title() {
    return this.model.id ? 'Edit Goal' : 'New Goal'
  }

  public reset() {
    this.model = new FinancialPlan()
    this.snapshot_name = ''
    this.snapshot_description = ''
  }

  public addPlan() {
    Vue.set(this, 'can_update_values', false)

    this.reset()

    this.model.sub_loading = true

    this.action = 'create'
    // this.$bvModal.show('edit-goal')
    this.managing_model = true

    this.bulk_months = FinancialPlanProductModule.init().getGoalPerMonth()

    setTimeout(() => {
      this.model.sub_loading = false

      Vue.set(this, 'can_update_values', true)
    }, 300)
  }

  public mounted() {
    this.loadFilters()
  }

  public getMonth(index: number) {
    return moment().month(index).format('MMM')
  }

  public rows(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    this.syncFilters()
    return FinancialPlan.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters, 'scope:year'],
    }).then(result => {
      this.loaded_items = FinancialPlan.toObjectList(result.data)
      this.records = result.records
      this.loading = false
      return this.loaded_items
    })
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.refresh()
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'goal-planning',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('goal-planning').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'goal-planning',
      data: null,
    })
  }

  public editPlan(item: FinancialPlan) {
    this.clone_from = null
    this.model = FinancialPlan.toObject(item)
    this.set_month_list()

    this.action = 'edit'
    // this.$bvModal.show('edit-goal')
    this.managing_model = true
  }

  public clonePlan(item: FinancialPlan) {
    this.reset()
    this.source_year = item.year
    this.action = 'clone'

    // this.$bvModal.show('edit-goal')
    this.managing_model = true
  }

  public deletePlan(item: FinancialPlan) {
    WebMessage.doubleConfirm(
      `This action will delete the full year plan data for ${item.year}. This action cannot be undone. Are you sure you want to proceed?`,
      'Delete Year Plan',
      'Yes, Delete full plan data',
      { okTitle: 'Delete' },
    ).then(result => {
      if (result) {
        item.delete().then(() => {
          this.refresh()
        })
      }
    })
  }

  public approvePlan(item: FinancialPlan) {
    this.model = FinancialPlan.toObject(item)
    this.$bvModal.show('approve-goal')
  }

  public confirmApprove() {
    this.model.approve(this.snapshot_name, this.snapshot_description).then(() => {
      this.$bvModal.hide('approve-goal')
      this.refresh()
      WebMessage.success('Plan approved successfully.')
      this.reset()
    })
  }

  public save() {
    this.loading = true

    if (this.action === 'clone') {
      WebMessage.info(
        "Generating new plan. This may take a while. You'll be notified when it's done.",
      )
      this.model
        .clonePlan(this.source_year, this.increment)
        .then((response: any) => {
          this.loading = true
          // this.$bvModal.hide('edit-goal')
          this.managing_model = false

          this.refresh()
          WebMessage.success('Plan generated successfully.')
        })
        .catch((error: WebMessage) => {
          this.loading = false
          WebMessage.error('We were unable to clone the plan. Please try again later.')
        })

      return
    }
    this.model.month_goals = this.bulk_months

    this.model
      .save()
      .then((response: any) => {
        this.loading = true
        // this.$bvModal.hide('edit-goal')
        this.managing_model = false
        this.locked_items = []

        this.locked_values = {}
        this.refresh()
      })
      .catch((error: WebMessage) => {
        this.loading = false
        WebMessage.error('We were unable to save the plan. Please try again later.')
      })
  }

  public formatNumeral(value: any) {
    return numeral(value).format('0.00a')
  }

  public reloadByIndex(index: number, delay = 0) {
    setTimeout(() => {
      // @ts-ignore
      this.loaded_items[index].subTableRow()
    }, delay)
  }

  public importPlans(plan: any) {
    this.import_to_plan = plan
    this.import_modal = true
  }

  public uploadImported() {
    this.importing_file = true
    this.import_to_plan.importPlan().then(() => {
      this.importing_file = false
      this.import_modal = false
      this.resetImport()
    })
  }

  public resetImport() {
    this.import_to_plan.model_files_meta = []
    this.import_to_plan.model_files_binary = []
    this.import_to_plan = null
    this.import_modal = false
  }

  public set_month_list() {
    this.model.sub_loading = true
    if (this.model.id && this.model.id.length) {
      this.model.financeGoal().then((response: any) => {
        this.bulk_months = response
        this.can_update_values = true
        this.calculate_table_totals()
        this.model.sub_loading = false
      })
    }
  }

  // distibute the values evenly advancedGoalSeek
  public distibuteGoal(action: string, increment: any = null) {
    let value_use: number = Number(this.model.goal.toFixed(2))

    if (action === 'even') {
      let record_count = 0
      let locked = 0

      for (let month_index = 0; month_index < this.bulk_months.length; month_index++) {
        const element = this.bulk_months[month_index]
        let products = element.ccl.map(cl => {
          cl.product_name = 'ccl'
          return cl
        })
        products = products.concat(
          element.ssl.map(sl => {
            sl.product_name = 'ssl'
            return sl
          }),
        )

        for (let product_index = 0; product_index < products.length; product_index++) {
          const found_product = products[product_index]
          if (found_product.key !== 'total') {
            if (
              !this.locked_items.includes(
                `${found_product.key}-${found_product.product_name}-${month_index}`,
              )
            ) {
              record_count++
            } else {
              locked += Number(
                this.locked_values[
                  `${found_product.key}-${found_product.product_name}-${month_index}`
                ],
              )
            }
          }
        }
      }

      if (locked > value_use) {
        WebMessage.error('The amount you entered is less than the locked amount')
        return
      }

      let amount = +new BigNumber(value_use).minus(locked).dividedBy(record_count).toFixed(2)

      let remaining = +new BigNumber(amount)
        .times(record_count)
        .plus(locked)
        .minus(value_use)
        .times(-1)
        .toFixed(2)

      let shared = Number((amount + remaining).toFixed(2))

      this.model.sub_loading = true

      let copy = JSON.parse(JSON.stringify(this.bulk_months))

      for (let month_index = 0; month_index < this.bulk_months.length; month_index++) {
        const element = this.bulk_months[month_index]
        let products = element.ccl.map((cl, cli) => {
          cl.product_name = 'ccl'
          cl.current_index = cli // original index in referenc inside array bulk_months, due to this map
          return cl
        })
        products = products.concat(
          element.ssl.map((sl, sli) => {
            sl.product_name = 'ssl'
            sl.current_index = sli // original index in referenc inside array bulk_months, due to this map
            return sl
          }),
        )

        for (let product_index = 0; product_index < products.length; product_index++) {
          const found_product = products[product_index]

          if (found_product.key !== 'total') {
            if (
              !this.locked_items.includes(
                `${found_product.key}-${found_product.product_name}-${month_index}`,
              )
            ) {
              copy[month_index][found_product.product_name][found_product.current_index].value = shared
            }
          }
        }
      }

      this.bulk_months = null

      Vue.set(this, 'bulk_months', copy)

      setTimeout(() => {
        this.model.sub_loading = false
      }, 300)
      return
    }

    // // Proportional TODO continue here james
    let locked = 0
    let total = 0

    for (let month_index = 0; month_index < this.bulk_months.length; month_index++) {
      const element = this.bulk_months[month_index]
      let products = element.ccl.map((cl, cli) => {
        cl.product_name = 'ccl'
        cl.current_index = cli // original index in referenc inside array bulk_months, due to this map
        return cl
      })
      products = products.concat(
        element.ssl.map((sl, sli) => {
          sl.product_name = 'ssl'
          sl.current_index = sli // original index in referenc inside array bulk_months, due to this map
          return sl
        }),
      )

      for (let product_index = 0; product_index < products.length; product_index++) {
        const found_product = products[product_index]
        if (found_product.key !== 'total') {
          if (
            this.locked_items.includes(
              `${found_product.key}-${found_product.product_name}-${month_index}`,
            )
          ) {
            locked += Number(
              this.locked_values[
                `${found_product.key}-${found_product.product_name}-${month_index}`
              ],
            )
          }
          total += Number(
            this.bulk_months[month_index][found_product.product_name][found_product.current_index]
              .value,
          )
        }
      }
    }

    if (locked > value_use) {
      WebMessage.error('The amount you entered is less than the locked amount')
    }

    let remaining = total - locked
    let target = value_use - locked
    this.model.sub_loading = true
    let copy = JSON.parse(JSON.stringify(this.bulk_months))

    let applied = 0

    for (let month_index = 0; month_index < this.bulk_months.length; month_index++) {
      const element = this.bulk_months[month_index]
      let products = element.ccl.map((cl, cli) => {
        cl.product_name = 'ccl'
        cl.current_index = cli // original index in referenc inside array bulk_months, due to this map
        return cl
      })
      products = products.concat(
        element.ssl.map((sl, sli) => {
          sl.product_name = 'ssl'
          sl.current_index = sli // original index in referenc inside array bulk_months, due to this map
          return sl
        }),
      )

      for (let product_index = 0; product_index < products.length; product_index++) {
        const found_product = products[product_index]
        if (found_product.key !== 'total') {
          if (
            !this.locked_items.includes(
              `${found_product.key}-${found_product.product_name}-${month_index}`,
            )
          ) {
            let originalitem = this.bulk_months[month_index][found_product.product_name][found_product.current_index]
            let new_value: any = new BigNumber(originalitem.value)
              .div(remaining)
              .times(target)
              .toFixed(2)

            if (!originalitem.value && !remaining) {
              // new_value = new BigNumber(target)
              //   .div(FinancialPlanProductModule.read(this.bulk_months).total_of_cells)
              //   .toFixed(2)
              new_value = 0
            }

            new_value = Number(new_value)

            copy[month_index][found_product.product_name][found_product.current_index].value = new_value

            applied += new_value
          }
        }
      }
    }

    this.bulk_months = null

    Vue.set(this, 'bulk_months', copy)

    setTimeout(() => {
      this.model.sub_loading = false
    }, 500)
  }

  public toogleLocks(region: string, product: string, month_index: number, value: number) {
    region = region.toLowerCase()
    product = product.toLowerCase()

    let key = `${region}-${product}-${month_index}`

    if (this.locked_items.includes(key)) {
      this.locked_items = this.locked_items.filter((item: any) => item !== key)
      delete this.locked_values[key]
    } else {
      this.locked_items.push(key)
      this.locked_values[key] = value
    }
  }

  public lock_row(lock: boolean, index: number) {
    for (let item in this.bulk_months[index]) {
      // ccl and ssl only
      if (['ssl', 'ccl'].includes(item)) {
        const product_object = this.bulk_months[index][item]
        const regions = product_object.map(reg => reg.key)
        for (let region of regions) {
          if (region !== 'total') {
            const { value } = product_object.find(c => c.key === region)
            this.toogleLocks(region, item, index, value)
          }
        }
      }
    }

    if (lock) {
      this.locked_rows.push(index)
    } else {
      this.locked_rows = this.locked_rows.filter(item => item !== index)
    }
  }

  public lock_column(lock: boolean, region: string, product: string) {
    // to assure that the region and product are always lowercase
    region = region.toLowerCase()
    product = product.toLowerCase()
    const key = `${region}-${product}`

    for (let index = 0; index < this.bulk_months.length; index++) {
      const row = this.bulk_months[index]
      let found_index = row[product].findIndex((item: any) => item.key === region)
      if (found_index >= 0) {
        const val = row[product][found_index].value
        this.toogleLocks(region, product, index, val)
      }
    }

    if (lock) {
      this.locked_columns.push(key)
    } else {
      this.locked_columns = this.locked_columns.filter((item: string) => item !== key)
    }
  }

  public toggle_columns(key: string) {
    this.collapsed_groups[key] = !this.collapsed_groups[key]
    this.model.sub_loading = true
    this.model.hide_columns(key, !this.collapsed_groups[key])
    setTimeout(() => {
      this.model.sub_loading = false
    }, 500)
  }

  public consolidatedSalesRep(item: FinancialPlan) {
    this.sales_rep_modal.item = item
    this.sales_rep_modal.consolidatedSalesRep(item).then(() => {
      this.sales_rep_modal.modal = true
    })
  }
}
