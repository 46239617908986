import router from '@/Routes'
import FinancialPlan from '@/models/FinancialPlan'
import WebMessage from '@/models/WebMessage'

export default [
  {
    key: 'year',
    label: 'Year',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
    thStyle: 'width:200px',
  },
  {
    key: 'view_goal',
    label: 'Plan',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'view_goal_planned',
    label: 'Pending Plan',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'view_goal_pipeline',
    label: 'Pipeline',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'view_goal_sold',
    label: 'Sold',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'view_goal_achieved',
    label: 'Achieved',
    sortable: true,
    filter: true,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: '',
    type: 'action_list',
    sortable: false,
    show: true,
    tdClass: 'actions',
    thStyle: { width: '200px' },
    actions: [
      {
        icon: 'eye',
        title: 'View',
        event: 'view',
        top_level: true,
        action: (plan: FinancialPlan) => {
          // @ts-ignore
          router.push({ name: 'GoalPlan', params: { year: plan.year } })
        },
      },
      {
        icon: 'pencil',
        title: 'Edit Year Goal',
        event: 'edit',
        top_level: true,
      },
      {
        icon: 'search',
        title: 'Overview',
        event: 'details',
      },
      {
        icon: 'upload',
        title: 'Import plan',
        event: 'import-plan',
      },
      {
        icon: 'download',
        title: 'Export plan',
        event: 'export',
        action: (plan: FinancialPlan) => {
          plan.exportAnnualPlan()
        },
      },
      {
        icon: 'funnel-dollar',
        title: 'Sales Rep Consolidated Goal',
        event: 'sales-rep-consolidated-goal',
      },
      {
        icon: 'stamp',
        title: 'Approve Plan & Create Snapshot',
        event: 'approve',
      },
      {
        icon: 'history',
        title: 'View Snapshots',
        event: 'view_snapshots',
        action: (plan: FinancialPlan) => {
          // @ts-ignore
          router.push({ name: 'GoalSnapshotView', params: { year: plan.year } })
        },
      },
      {
        icon: 'redo-alt',
        title: 'View Reforecast',
        event: 'view_reforecasts',
        action: (plan: FinancialPlan) => {
          // @ts-ignore
          router.push({ name: 'GoalReforecastView', params: { goal: plan.id, year: plan.year } })
        },
      },
      {
        icon: 'clone',
        title: 'Clone Year Goal',
        event: 'clone',
      },
      {
        icon: 'trash',
        title: 'Delete Year Goal',
        event: 'delete',
      },
      {
        icon: 'chart-area',
        title: 'General Overview',
        event: 'overview',
        top_level: true,
        action: (plan: FinancialPlan) => {
          // @ts-ignore
          router.push({ name: 'GoalOverView', params: { year: plan.year } })
        },
      },
    ],
  },
]
